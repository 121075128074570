import { SignTypedDataParameters } from '@wagmi/core';

import { randomBytes } from 'crypto';
import { Address } from 'viem';
import { useSendTransaction } from 'wagmi';

import { HashLock, OrderStatus } from '@1inch/cross-chain-sdk';

import { ApiOptions } from '../helpers/api';
import { deserialize, JsonApiObject } from '../helpers/jsonapi';
import Api from './ApiProvider';
import {
  GetOneInchGasPricesResponse,
  GetOneInchSingleChainQuoteResponse,
  GetOneInchTermsResponse,
  GetOneInchTokenAllowanceResponse,
  GetOneInchTxApproveResponse,
  GetTokenPriceInUSDResponse,
  MakeOneInchTokensSwapResponse,
  OneInchCrossChainQuote,
  OneInchCrossChainQuoteResponse,
  OneInchCrossChainSwapPresetVariant,
  OneInchNetwork,
  OneInchToken,
  StoreSwapTxResponse,
} from './types';

export const getOneInchNetworks = async (options?: ApiOptions) => {
  const data = await Api.get('v2/integrations/1inch', options).json<JsonApiObject>();

  return deserialize<OneInchNetwork[]>(data);
};

export const getOneInchNetwork = getOneInchNetworks;

export const getOneInchTokens = async (networkId: OneInchNetwork['id'], options?: ApiOptions) => {
  const data = await Api.get(
    `v2/integrations/1inch/${networkId}/tokens`,
    options,
  ).json<JsonApiObject>();

  return deserialize<OneInchToken[]>(data);
};

export const getOneInchSingleChainQuote = async (
  options: ApiOptions<{
    searchParams: {
      fromTokenAddress: string;
      toTokenAddress: string;
      amount: string;
      networkId: OneInchNetwork['id'];
    };
  }>,
  signal?: AbortSignal,
) => {
  return Api.get(`v2/integrations/1inch/${options.searchParams.networkId}/quote`, {
    ...options,
    signal,
  }).json<GetOneInchSingleChainQuoteResponse>();
};

export const getOneInchCrossChainQuote = async (
  options: ApiOptions<{
    searchParams: {
      srcTokenAddress: string;
      dstTokenAddress: string;
      amount: string;
      srcChain: OneInchNetwork['id'];
      dstChain: OneInchNetwork['id'];
      walletAddress: string;
    };
  }>,
  signal?: AbortSignal,
) => {
  return Api.get(`v2/integrations/1inch/cross-chain/quote`, {
    ...options,
    signal,
  }).json<OneInchCrossChainQuoteResponse>();
};

export const getOneInchTokenAllowance = (
  networkId: OneInchNetwork['id'],
  options: ApiOptions<{
    searchParams: {
      tokenAddress: Address;
      walletAddress: Address;
    };
  }>,
) => {
  return Api.get(
    `v2/integrations/1inch/${networkId}/allowance`,
    options,
  ).json<GetOneInchTokenAllowanceResponse>();
};

export const getOneInchTxApprove = async ({
  networkId,
  options,
}: {
  networkId: OneInchNetwork['id'];
  options: ApiOptions<{
    searchParams: {
      tokenAddress: Address;
      amount: number;
    };
  }>;
}) => {
  return Api.get(
    `v2/integrations/1inch/${networkId}/approve/tx`,
    options,
  ).json<GetOneInchTxApproveResponse>();
};

export const useIncreaseAllowance = () => {
  const { sendTransactionAsync } = useSendTransaction();

  return {
    icreaseAllowance: async ({
      networkId,
      options,
    }: {
      networkId: OneInchNetwork['id'];
      options: ApiOptions<{
        searchParams: { tokenAddress: Address; amount: number; walletAddress: Address };
      }>;
    }) => {
      const tx = await getOneInchTxApprove({
        networkId,
        options,
      });

      const hash = await sendTransactionAsync({
        ...(tx.data as any),
      });

      if (!hash) {
        return;
      }
    },
  };
};

export const makeOneInchSingleChainTokensSwap = async ({
  networkId,
  options,
}: {
  networkId: OneInchNetwork['id'];
  options: ApiOptions<{
    searchParams: {
      fromTokenAddress: string;
      toTokenAddress: string;
      amount: number;
      walletAddress: string;
      slippage: number;
    };
  }>;
}) => {
  return Api.get(`v2/integrations/1inch/${networkId}/swap`, options).json<
    | MakeOneInchTokensSwapResponse
    | {
        message: string;
        error: {
          message: string;
        };
      }
  >();
};

interface OneInchCrossChainSwapBasicParams {
  srcChain: number;
  dstChain: number;
  srcTokenAddress: string;
  dstTokenAddress: string;
  walletAddress: string;
  amount: string;
  quote: OneInchCrossChainQuote;
  preset: OneInchCrossChainSwapPresetVariant;
}

interface BuildOneInchCrossChainSwapOrderParams extends OneInchCrossChainSwapBasicParams {
  secretsHashList: Array<string>;
}

interface OneInchCrossChainSecret {
  value: string;
  hash: string;
}

const generateOneInchCrossChainSwapSecrets = (count: number): Array<OneInchCrossChainSecret> => {
  return Array.from({
    length: count,
  }).map(() => {
    const value = '0x' + randomBytes(32).toString('hex');
    const hash = HashLock.hashSecret(value);

    return {
      value,
      hash,
    };
  });
};

export type OneInchCrossChainSwapOrder = {
  orderHash: string;
  typedData: SignTypedDataParameters;
  extension: string;
};

export interface OneInchBuildSwapOrderResult {
  newOrder: OneInchCrossChainSwapOrder;
  operationId: string;
}

export const buildOneInchCrossChainSwapOrder = async (
  params: BuildOneInchCrossChainSwapOrderParams,
): Promise<OneInchBuildSwapOrderResult> => {
  return await Api.post('v2/integrations/1inch/cross-chain/order/build', {
    json: params,
  }).json<OneInchBuildSwapOrderResult>();
};

export const getOneInchCrossChainSwapOrderStatus = async (
  orderHash: string,
): Promise<OrderStatus> => {
  const { status } = await Api.get('v2/integrations/1inch/cross-chain/order', {
    searchParams: {
      orderHash,
    },
  }).json<{ status: OrderStatus }>();

  return status;
};

export interface OneInchCrossChainSwapOrderOperation {
  operationId: string;
  secrets: Array<OneInchCrossChainSecret>;
  order: OneInchCrossChainSwapOrder;
}

interface OneInchSubmitCrossChainSwapOrderParams {
  srcChainId: number;
  extension: string;
  quoteId: string;
  signature: string;
  order: OneInchCrossChainSwapOrder;
  operationId: string;
  walletAddress: string;
}

export const submitOneInchCrossChainSwapOrder = async ({
  order,
  walletAddress,
  ...params
}: OneInchSubmitCrossChainSwapOrderParams) => {
  const result = await Api.post('v2/integrations/1inch/cross-chain/order/submit', {
    json: {
      order: {
        ...order.typedData.message,
        extension: params.extension,
      },
      ...params,
    },
  }).json<OneInchBuildSwapOrderResult>();

  return result;
};

interface CheckOneInchCrossChainSwapPendingSecretsParams {
  orderHash: string;
}

interface CheckOneInchCrossChainSwapPendingSecretsResult {
  fills?: Array<{ idx: number }>;
}

export const checkOneInchCrossChainSwapPendingSecrets = async ({
  orderHash,
}: CheckOneInchCrossChainSwapPendingSecretsParams): Promise<CheckOneInchCrossChainSwapPendingSecretsResult> => {
  return Api.get(`v2/integrations/1inch/cross-chain/order/check-secret`, {
    searchParams: {
      orderHash,
    },
  }).json<CheckOneInchCrossChainSwapPendingSecretsResult>();
};

interface SubmitOneInchCrossChainSwapPendingSecret {
  orderHash: string;
  secret: string;
}

export const submitOneInchCrossChainSwapPendingSecret = async ({
  orderHash,
  secret,
}: SubmitOneInchCrossChainSwapPendingSecret): Promise<void> => {
  await Api.post(`v2/integrations/1inch/cross-chain/order/submit-secret`, {
    json: {
      orderHash,
      secret,
    },
  });
};

export const storeSwapTx = async ({
  networkId,
  options,
}: {
  networkId: OneInchNetwork['id'];
  options: ApiOptions<{
    json: {
      tx: string;
      operationId: string;
    };
  }>;
}) => {
  const data = await Api.post(
    `v2/integrations/1inch/${networkId}/swap/confirm`,
    options,
  ).json<JsonApiObject>();

  return deserialize<StoreSwapTxResponse>(data);
};

export const getTokenPriceInUSD = async (networkId: number, tokenAddress: Address) => {
  const data = await Api.get(
    `v2/integrations/1inch/${networkId}/tokens/${tokenAddress}`,
  ).json<JsonApiObject>();

  return deserialize<GetTokenPriceInUSDResponse>(data);
};

export const getOneInchGasPrices = async (networkId: number) => {
  const data = await Api.get(`v2/integrations/1inch/${networkId}/gas-price`).json<JsonApiObject>();

  return deserialize<GetOneInchGasPricesResponse>(data);
};

export const getOneInchTerms = async (options?: ApiOptions) => {
  return Api.get(
    'v2/integrations/1inch/terms-and-conditions',
    options,
  ).json<GetOneInchTermsResponse>();
};
