import Api, { ApiOptions } from './ApiProvider';

export const logout = (options?: ApiOptions<{ json?: { name: string } }>) => {
  return Api.post('v2/magicid/auth/logout', {
    ...options,
    headers: {
      ...options?.headers,
      'referer-original': document.referrer || '',
    },
  }).json<{ message: string }>();
};
