import { mergeKyRequestHeaders, protectedByNonce } from '@shared/helpers/api';
import { captchaDecorator } from '@shared/helpers/captcha';

import Api, { ApiOptions } from './ApiProvider';
import {
  RequestSignResult,
  SocialInterface,
  TelegramUser,
  WalletInterface,
  WalletType,
  WalletTypeInUppercase,
} from './types';

export interface AuthorizationStatus {
  id: string;
  type: string;
  attributes: {
    authorized: boolean;
  };
}

export const getIsUserAuthorized = (options?: ApiOptions) => {
  return Api.get('magicid/user/authorized', options).json<AuthorizationStatus>();
};

export const loginSendPasswordlessEmail = protectedByNonce(
  (options?: ApiOptions<{ json: { email: string; referredBy?: string } }>) => {
    return Api.post('v2/magicid/auth/login/email', {
      ...options,
      headers: {
        ...options?.headers,
        'referer-original': document.referrer || '',
      },
    }).json();
  },
);

export const loginSendGoogleOAuthCode = protectedByNonce(
  (options?: ApiOptions<{ json: { code: string; referredBy?: string } }>) => {
    return Api.post('v2/magicid/auth/login/google', {
      ...options,
      headers: mergeKyRequestHeaders(options?.headers, {
        'referer-original': document.referrer || '',
      }),
    }).json();
  },
);

export const loginVerifyWallet = (
  options?: ApiOptions<{
    json: { network: string; pub_key: string; wallet: string; signature: string; message: string };
  }>,
) =>
  Api.post('v2/magicid/auth/login/wallet', {
    ...options,
    headers: {
      ...options?.headers,
      'referer-original': document.referrer || '',
    },
  }).json();

export const loginConnectWallet = (
  options?: ApiOptions<{
    json: { network: string; pub_key: string; wallet: string; referredBy?: string };
  }>,
) => Api.post('v2/magicid/user/wallet/connect', options).json();

export const loginTestUser = (options: ApiOptions<{ json: { id: number } }>) =>
  Api.post('v2/magicid/auth/login/test-user', {
    ...options,
    headers: {
      ...options?.headers,
      'referer-original': document.referrer || '',
    },
  }).json<{ message: string }>();

export const loginWallet = async (
  options?: ApiOptions<{
    json: {
      network: string;
      pub_key: string;
      wallet: string;
      signature: string;
      message: string;
      referredBy?: string;
    };
  }>,
) => {
  await loginVerifyWallet(options);

  return loginConnectWallet(options);
};

export const loginWithWallet = captchaDecorator<
  ApiOptions<{
    json: Pick<RequestSignResult, 'signature' | 'network' | 'message'> & {
      pub_key: RequestSignResult['address'];
      wallet: WalletTypeInUppercase;
    };
  }>,
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    message: 'Authorized' | (string & {});
    referral: boolean;
    newUser: boolean;
    error?: any;
  }
>(
  async (options) =>
    Api.post('v2/magicid/auth/login/wallet', {
      ...options,
      headers: {
        ...options?.headers,
        'referer-original': document.referrer || '',
      },
    }).json(),
  'loginWithWallet',
);

export const connectToMagicID = (
  options: ApiOptions<{
    json: Pick<RequestSignResult, 'network'> & {
      pub_key: RequestSignResult['address'];
      wallet: WalletType;
      referredBy?: string;
    };
  }>,
) =>
  Api.post('v2/magicid/user/wallet/connect', options).json<{
    connected: number;
    createdAt: string;
    displayedName: string | null;
    email: string | null;
    emailCodeInvalidAttempts: number;
    emailConfirmed: number;
    id: number;
    name: string | null;
    normalized_email: string | null;
    referral_id: string | null;
    referred_by_id: string | null;
    registration_device: string | null;
    registration_ip: string | null;
    socials: (SocialInterface['attributes'] & Pick<SocialInterface, 'id'>)[];
    updatedAt: string;
    uuid: string;
    wallets: (WalletInterface['attributes'] & Pick<WalletInterface, 'id'>)[];
    error?: unknown;
  }>();

export interface SendGoogleOAuthCodeApiOptionsPayload {
  code: string;
  referredBy?: string;
}

export interface SendGoogleOAuthCodeApiOptions {
  json: SendGoogleOAuthCodeApiOptionsPayload;
}

export interface SendGoogleOAuthCodeApiResponse {
  // eslint-disable-next-line @typescript-eslint/ban-types
  message: 'Authorized' | (string & {});
  newUser: boolean;
}

export const sendGoogleOAuthCode = protectedByNonce(
  ({ headers, ...restOptions }: ApiOptions<SendGoogleOAuthCodeApiOptions>) => {
    return Api.post('v2/magicid/auth/login/google', {
      ...restOptions,
      headers: mergeKyRequestHeaders(headers, {
        'referer-original': document.referrer || '',
      }),
    }).json<SendGoogleOAuthCodeApiResponse>();
  },
);

export interface SendPasswordlessEmailApiOptionsPayload {
  from: string;
  email: string;
  referredBy?: string;
}

export interface SendPasswordlessEmailApiOptions {
  json: SendPasswordlessEmailApiOptionsPayload;
}

export interface SendPasswordlessEmailApiResponse {
  attributes: { email: string };
  email: string;
  id: number;
  type: 'JOIN_LINK';
}

export const sendPasswordlessEmail = protectedByNonce(
  ({ headers, ...restOptions }: ApiOptions<SendPasswordlessEmailApiOptions>) => {
    return Api.post('v2/magicid/auth/login/email', {
      ...restOptions,
      headers: mergeKyRequestHeaders(headers, {
        'referer-original': document.referrer || '',
      }),
    }).json<SendPasswordlessEmailApiResponse>();
  },
);

export const deleteWallet = ({ id }: { id: number }) =>
  Api.delete(`v2/magicid/user/wallets/${id}`).json();

export const confirmExternalConnect = (options: ApiOptions<{ json: { hash: string } }>) =>
  Api.post('v2/magicid/external/magic-boost/connect', options).json();

// Method only for testing `magic-boost.connect` socket events
export const triggerMagicBoostExternalConnect = (
  options: ApiOptions<{ json: { magicId: number; name: string; access: string[] } }>,
) => Api.post('v2/magicid/auth/magic-boost/connect', options).json();

export interface LoginByTelegramApiOptionsPayload {
  userData: TelegramUser;
  userCreationAllowed: boolean;
  referredBy?: string;
}

export interface LoginByTelegramApiOptions {
  json: LoginByTelegramApiOptionsPayload;
}

export const loginByTelegram = protectedByNonce(
  ({ headers, ...restOptions }: ApiOptions<LoginByTelegramApiOptions>) => {
    return Api.post('v2/magicid/auth/login/telegram', {
      ...restOptions,
      headers: mergeKyRequestHeaders(headers, {
        'referer-original': document.referrer || '',
      }),
    }).json();
  },
);

export const getXLoginUrl = ({ from }: { from: string }, options?: ApiOptions) =>
  Api.get('v2/magicid/auth/login/x', {
    ...options,
    searchParams: { from },
    headers: {
      ...options?.headers,
      'referer-original': document.referrer || '',
    },
  }).json<{
    meta: { link: string };
  }>();

export interface LoginByXapiOptionsPayload {
  state: string;
  oauthToken: string;
  oauthVerifier: string;
  userCreationAllowed?: boolean;
  referredBy?: string;
}

export interface LoginByXApiOptions {
  json: LoginByXapiOptionsPayload;
}

export interface LoginByXApiResponse {
  meta: {
    link: string;
  };
}

export const loginByX = protectedByNonce((options?: ApiOptions<LoginByXApiOptions>) => {
  return Api.post('v2/magicid/auth/login/x', {
    ...options,
    headers: mergeKyRequestHeaders(options?.headers, {
      'referer-original': document.referrer || '',
    }),
  }).json<LoginByXApiResponse>();
});

export const getDiscordLoginUrl = ({ from }: { from: string }) => {
  return Api.get('v2/magicid/auth/login/discord', {
    searchParams: { from },
    headers: {
      'referer-original': document.referrer || '',
    },
  }).json<{
    url: string;
  }>();
};

export interface LoginByDiscordApiOptionsPayload {
  state: string;
  code: string;
  referredBy?: string;
  userCreationAllowed?: boolean;
}

export interface LoginByDiscordApiOptions {
  json: LoginByDiscordApiOptionsPayload;
}

export interface LoginByDiscordApiResponse {
  message: string;
}

export const loginByDiscord = protectedByNonce((options?: ApiOptions<LoginByDiscordApiOptions>) => {
  return Api.post('v2/magicid/auth/login/discord', {
    ...options,
    headers: mergeKyRequestHeaders(options?.headers, {
      'referer-original': document.referrer || '',
    }),
  }).json<LoginByDiscordApiResponse>();
});
